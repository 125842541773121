// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datascience-index-js": () => import("./../../../src/pages/datascience/index.js" /* webpackChunkName: "component---src-pages-datascience-index-js" */),
  "component---src-pages-datascience-notebook-js": () => import("./../../../src/pages/datascience/notebook.js" /* webpackChunkName: "component---src-pages-datascience-notebook-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portafolio-gojump-js": () => import("./../../../src/pages/portafolio/Gojump.js" /* webpackChunkName: "component---src-pages-portafolio-gojump-js" */),
  "component---src-pages-portafolio-observa-js": () => import("./../../../src/pages/portafolio/Observa.js" /* webpackChunkName: "component---src-pages-portafolio-observa-js" */),
  "component---src-pages-portafolio-siderval-js": () => import("./../../../src/pages/portafolio/Siderval.js" /* webpackChunkName: "component---src-pages-portafolio-siderval-js" */),
  "component---src-pages-portafolio-udd-js": () => import("./../../../src/pages/portafolio/Udd.js" /* webpackChunkName: "component---src-pages-portafolio-udd-js" */),
  "component---src-pages-portafolio-zinkerz-js": () => import("./../../../src/pages/portafolio/Zinkerz.js" /* webpackChunkName: "component---src-pages-portafolio-zinkerz-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

